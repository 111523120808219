import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MenuComponent } from "./dialogs/menu/menu.component";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SteppedEase } from "gsap";
import { Observable, of } from "rxjs";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NavigationEnd, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements OnInit {
  title = "TeoriaM";
  starTyping = false;
  nombre = "";
  correo = "";
  idea = "";
  fecha = "";
  constructor(
    public dialog: MatDialog,
    private gtmService: GoogleTagManagerService,
    public router: Router,
    public httpClient: HttpClient,
    private _snackBar: MatSnackBar
  ) {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
  ngOnInit(): void {
    var that = this;
    const vh = (coef) => window.innerHeight * (coef / 100);
    const vw = (coef) => window.innerWidth * (coef / 100);
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#alpha", { translateX: vw(-50) });
    gsap.set("#omega", { translateX: vw(50) });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#ao",
          start: vh(-85) + " top",
          end: vh(100) + " top",
          scrub: true,
          markers: false,
        },
      })
      .to("#alpha", {
        translateX: vw(100),
      })
      .to(
        "#omega",
        {
          translateX: vw(-100),
        },
        "<"
      );
    //Tiping
    gsap.timeline({
      scrollTrigger: {
        trigger: "#text",
        start: vh(-85) + " top",
        end: vh(100) + " top",
        scrub: true,
        markers: false,
        onEnter(self) {
          that.starTyping = true;
        },
      },
    });
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }
  abrirMenu() {
    this.dialog.open(MenuComponent, {
      width: "100vw",
      maxWidth: "100vw",
      height: "100vh",
    });
  }
  formValido() {
    return !!this.nombre && !!this.correo && !!this.idea && !!this.fecha;
  }
  enviarFormulario() {
    if (this.formValido()) {
      var data = {
        nombre: this.nombre.toString(),
        correo: this.correo.toString(),
        idea: this.idea.toString(),
        fecha: this.fecha.toString(),
      };
      this.nombre = "";
      this.correo = "";
      this.idea = "";
      this.fecha = "";
      this._snackBar.open("Información enviada", "OK", {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        duration: 5000,
      });
      this.httpClient.post("https://us-central1-teoriam.cloudfunctions.net/api/enviarFormulario", data).subscribe((res) => {

      });
    }
  }
}
